import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import styles from './SelectProductCovenant.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../../../components/PageTitle';
import PageSubTitle from '../../../../../components/PageSubTitle';
import ProductList from '../../../../Query/UnitaryQuery/ProductListSimulation';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../../components/ToastValidationSimulation';
import Logo from '../../../../../components/Logo';
import NavBarAgent from '../../../../../components/NavBar/Agent_Partner';

export default function ProductSelectionCovenantBatchAgent() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [selectedProductDescription, setSelectedProductDescription] = useState<string | null>(null);

  const subtitleTextClient = "Revise os dados e selecione o produto.";

  const handleProductSelection = (selectedId: string, selectedDescription: string) => {
    setSelectedProductId(selectedId);
    setSelectedProductDescription(selectedDescription);
    setToastMessage('');
    localStorage.setItem('selectedProductId', selectedId);
    localStorage.setItem('selectedProductDescription', selectedDescription);
  };

  const handleBack = () => {
    window.location.href = '/ConsultaLote/Convenios';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProductId) {
      setToastMessage('Por favor, selecione um produto antes de prosseguir.');
      return;
    }

    window.location.href = '/ConsultaLote/Convenios/SelecaoBancos';
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta em Lote</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <ProductList
            onProductSelect={handleProductSelection}
            selectedProductId={selectedProductId}
          />
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>}
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}