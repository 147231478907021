import { useState, useEffect } from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import PageTitle from '../../../../../components/PageTitle';
import PageSubTitle from '../../../../../components/PageSubTitle';
import bmgLogo from '../../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../../assets/images/banco c6.png';
import ToastValidationSimulation from '../../../../../components/ToastValidationSimulation';
import masterLogo from '../../../../../assets/images/banco master.png';
import DropDownButtonPrimary from '../../../../../components/Buttons/DropdownButtonPrimary';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import noOpportunityImage from '../../../../../assets/images/lerotriste.png';
import Logo from '../../../../../components/Logo';
import { URL_SIMULATIONS } from '../../../../../config';
import NavBarAgent from '../../../../../components/NavBar/Agent_Partner';

export default function ReturnDataCovenantAgent() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [result, setResult] = useState<any>(null);
  const [pathCovenant, setPathCovenant] = useState('')
  const [opportunityCount, setOpportunityCount] = useState<number>(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const simulationId = searchParams.get('iS');

  const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
  const covenantDescriptionLocalStorage = formDataLocalStorage.covenantDescription;
  const dropdownItems = [
    { label: covenantDescriptionLocalStorage, link: '/Agente/ConsultaUnitaria/Convenio/DadosCliente' },
    { label: 'Outro Convênio', link: '/Agente/ConsultaUnitaria/Convenios' }
  ];

  const subtitleTextClient = "Confira o retorno da simulação";

  useEffect(() => {
    const fetchSimulationData = async () => {
      if (simulationId) {
        try {
          const response = await fetch(`${URL_SIMULATIONS}/proposal/simulation/find-by-id?id=${simulationId}`);
          const data = await response.json();
          const conditions = data[0]?.conditions.conditions;
          const pathCovenantReturn = data[0].path
          
          setResult(conditions);
          setPathCovenant(pathCovenantReturn)

          let totalOpportunities = 0;

          const banks = ['bmg', 'master'];
          banks.forEach(bank => {
            if (conditions[bank]?.length > 0 && conditions[bank][0].status === 'success') {
              totalOpportunities += conditions[bank].length;
            }
          });

          if (conditions.c6 && conditions.c6.length > 0 && conditions.c6[0].status === 'success') {
            totalOpportunities += conditions.c6.length;
          }

          setOpportunityCount(totalOpportunities);
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
      }
    };

    fetchSimulationData();
  }, [simulationId]);

  const getLogoPath = (bank: string) => {
    switch (bank) {
      case 'bmg': return bmgLogo;
      case 'master': return masterLogo;
      case 'c6': return c6Logo;
      default: return '';
    }
  };

  const formatCurrency = (value: any) => {
    const numberValue = parseFloat(value);
    return isNaN(numberValue)
      ? 'N/A'
      : `R$ ${numberValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Retorno da simulação</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row>
            <Col className="d-flex justify-content-start">
              {opportunityCount > 0 ? (
                <>
                  <Badge bg="success" className={`${styles.oportunityBage} me-2`}>
                    <FontAwesomeIcon icon={faSearchDollar}/> Você tem {opportunityCount} oportunidade(s) de negócio!
                  </Badge>
                  <Badge bg="secondary"> 
                    {covenantDescriptionLocalStorage}
                  </Badge>
                </>
              ) : (
                <Badge bg="secondary" className={`${styles.oportunityBage} me-2`}>
                  <FontAwesomeIcon icon={faSearchDollar}/> Nenhuma oportunidade de negócio no momento
                </Badge>
              )}
            </Col>
          </Row>

          <Row className={`${styles.resultSimulation} mt-5`}>
            {opportunityCount > 0 ? (
              <Carousel responsive={responsive}>
                {result?.bmg && result.bmg.length > 0 && result.bmg[0].status === "success" && (
                  <Col xs={12} sm={6} md={4} className="mb-3">
                    <Card className={styles.cardContainer}>
                      <Card.Header className={styles.cardHeader}>
                        Novo
                      </Card.Header>
                      <Card.Body className={styles.cardBody}>
                        <div className={styles.logoContainer}>
                          <img src={getLogoPath('bmg')} alt="BMG logo" className={styles.bankLogo} />
                          <img alt={covenantDescriptionLocalStorage} className={styles.covenantLogo} />
                        </div>
                        <div className={styles.netAmount}>
                          {formatCurrency(result.bmg[0].netAmount)}
                        </div>
                        <Container className={styles.textAmount}>
                          <p>{result.bmg[0].installmentQuantity} parcelas de R$ {formatCurrency(result.bmg[0].installmentAmount)}</p>
                          <p>{Number(result.bmg[0].clientRate).toFixed(1)}% ao mês</p>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                )}

                {result?.c6 && result.c6.length > 0 && result.c6[0].status === "success" && (
                  <Col xs={12} sm={6} md={4} className="mb-3">
                    <Card className={styles.cardContainer}>
                      <Card.Header className={styles.cardHeader}>
                        {result.c6[0].product}
                      </Card.Header>
                      <Card.Body className={styles.cardBody}>
                        <div className={styles.logoContainer}>
                          <img src={getLogoPath('c6')} alt="C6 logo" className={styles.bankLogo} />
                          <img  alt={covenantDescriptionLocalStorage} className={styles.covenantLogo} />
                        </div>
                        <div className={styles.netAmount}>
                          {formatCurrency(result.c6[0].netAmount)}
                        </div>
                        <Container className={styles.textAmount}>
                          <p>{result.c6[0].installmentQuantity} parcelas de R$ {formatCurrency(result.c6[0].installmentAmount)}</p>
                          <p>{Number(result.c6[0].clientRate).toFixed(1)}% ao mês</p>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                )}

                {result?.master && result.master.map((master: any, index: number) => (
                  master.status === "success" && (
                    <Col key={index} xs={12} sm={6} md={4} className="mb-3">
                      <Card className={styles.cardContainer}>
                        <Card.Header className={styles.cardHeader}>
                          {master.product}
                        </Card.Header>
                        <Card.Body className={styles.cardBody}>
                          <div className={styles.logoContainer}>
                            <img src={getLogoPath('master')} alt="Master logo" className={styles.bankLogo} />
                            <img src={pathCovenant}  alt={covenantDescriptionLocalStorage} className={styles.covenantLogo} />
                          </div>
                          <div className={styles.netAmount}>
                            {formatCurrency(master.netAmount)}
                          </div>
                          <Container className={styles.textAmount}>
                            <p>{master.installmentQuantity} parcelas de R$ {formatCurrency(master.installmentAmount)}</p>
                            <p>{Number(master.clientRate).toFixed(1)}% ao mês</p>
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                ))}
              </Carousel>
            ) : (
              <div className={styles.noOpportunityContainer}>
                <img src={noOpportunityImage} className={styles.noOpportunityImage} />
              </div>
            )}
          </Row>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-end'>
              <DropDownButtonPrimary title="Nova Simulação" items={dropdownItems} />
            </Col>
          </Row>
        </Container>
      </motion.div>
      <ToastValidationSimulation message={toastMessage} />
    </>
  );
}
