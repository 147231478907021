import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BankCard, BankLogo, BankName, CheckIcon } from './BankListSimulation';
import { URL_SIMULATIONS } from '../../../../../../config';

interface Bank {
  bankId: string;
  path: string;
  bankName: string;
}

interface BankListSimulationCovenantProps {
  isSelectAll: boolean;
  onSelectionChange: (selectedIds: string[]) => void;
}

const formatBankName = (name: string) => {
  return name
    .replace(/BANCO BMG/g, 'bmg')
    .replace(/BANCO|BANK|SOCIETY|CORP/g, '')
    .trim()
    .toLowerCase();
};

const BankListSimulationCovenant: React.FC<BankListSimulationCovenantProps> = ({ isSelectAll, onSelectionChange }) => {
  const [selectedBankNames, setSelectedBankNames] = useState<string[]>([]);
  const [selectedBankIds, setSelectedBankIds] = useState<string[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const covenantId = localStorage.getItem('covenantId')

  useEffect(() => {
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBankNames') || '[]');
    const savedSelectedBankIds = JSON.parse(localStorage.getItem('selectedBankIds') || '[]');
    setSelectedBankNames(savedSelectedBanks);
    setSelectedBankIds(savedSelectedBankIds);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedBankNames', JSON.stringify(selectedBankNames));
    localStorage.setItem('selectedBankIds', JSON.stringify(selectedBankIds));
    onSelectionChange(selectedBankNames);
  }, [selectedBankNames, selectedBankIds, onSelectionChange]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await fetch(`${URL_SIMULATIONS}/bank/find-by-covenant-id?covenantId=${covenantId}`);
        if (!response.ok) throw new Error('Failed to fetch banks');

        const data = await response.json();
      
        setBanks(data.map((bank: Bank) => ({
          bankId: bank.bankId,
          bankName: bank.bankName,
          path: bank.path,
        })));
      } catch (error) {
        setError(true);
        console.error('Error fetching bank data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, [covenantId]);

  useEffect(() => {
    if (isSelectAll) {
      const allBanks = banks.map(bank => formatBankName(bank.bankName));
      setSelectedBankNames(allBanks);
      setSelectedBankIds(banks.map(bank => bank.bankId));
    } else {
      setSelectedBankNames([]);
      setSelectedBankIds([]);
    }
  }, [isSelectAll, banks]);

  const handleSelectBank = (bankName: string, bankId: string) => {
    const formattedName = formatBankName(bankName);

    setSelectedBankNames(prevSelected =>
      prevSelected.includes(formattedName)
        ? prevSelected.filter(name => name !== formattedName)
        : [...prevSelected, formattedName]
    );

    setSelectedBankIds(prevSelected =>
      prevSelected.includes(bankId)
        ? prevSelected.filter(id => id !== bankId)
        : [...prevSelected, bankId]
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Bancos não cadastrados para o convênio selecionado.</p>;

  return (
    <Row className='d-flex justify-content-center mt-5 mb-5'>
      {banks.map(bank => (
        <BankCard
          sm={12} md={2} key={bank.bankId}
          onClick={() => handleSelectBank(bank.bankName, bank.bankId)}
          className={selectedBankNames.includes(formatBankName(bank.bankName)) ? 'selected' : ''}
        >
          <BankLogo src={bank.path} alt={`${bank.bankName} logo`} />
          <BankName>{bank.bankName}</BankName>
          {selectedBankNames.includes(formatBankName(bank.bankName)) && (
            <CheckIcon icon={faCheck} className="selected" />
          )}
        </BankCard>
      ))}
    </Row>
  );
};

export default BankListSimulationCovenant;