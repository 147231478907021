import styles from './ChangePassword.module.scss';
import lero from '../../assets/images/lero_tec.png';
import { useState } from 'react';
import ButtonLogin from '../../components/Buttons/ButtonLogin';
import { BASE_URL } from '../../config';
import axios from 'axios';
import ToastWarning from '../../components/ToastWarning';

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const id = localStorage.getItem('idUser');
    const token = localStorage.getItem('token');

    const redirectUser = (userType: string, typeId: number) => {
        const routes: { [key: string]: { [key: number]: string } } = {
            Partner: {
                1: "/Agente/PainelGeral",
                2: "/PainelGeral",
            },
            Employee: {
                7: "/HomeCadastro",
                4: "/PainelGeral",
                2: "/PainelGeral",
                12: "/Portabilidade/Parametros"
            },
        };

        const route = routes[userType]?.[typeId!];
        if (route) {
            window.location.href = route;
        } else {
            setToastMessage(userType === "Partner" ? "Tipo de parceiro desconhecido" : "Tipo de posição desconhecida");
        }
    };

    const handleSubmit = async () => {
        if (!token || !id) return;

        try {
            const res = await fetch(`${BASE_URL}/usuario/atualizar-senha/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ password: newPassword, password_confirmation: confirmPassword }),
            });

            if (!res.ok) throw new Error('Erro ao alterar senha');

            setToastMessage('Senha alterada com sucesso!');

            const { data: { Usuario } } = await axios.get(`${BASE_URL}/usuario/perfil-usuario`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (Usuario.userType === "Partner") {
                redirectUser("Partner", Usuario.partnerData?.type?.type_partner_id);
            } else if (Usuario.userType === "Employee") {
                redirectUser("Employee", Usuario.employeeData?.position?.position_id);
            } else {
                setToastMessage("Tipo de usuário desconhecido");
            }
        } catch (error) {
            setToastMessage('Não foi possível alterar a senha.');
        } finally {
            localStorage.removeItem('idUser');
        }
    };

    return (
        <div className={styles.mainSection}>
            <div className={`${styles.loginContainer} p-5`}>
                <img src={lero} alt="Mascote" />
                <div className={`${styles.inputGroup} mt-5`}>
                    {["Nova senha", "Confirmar senha"].map((label, index) => (
                        <div key={label} className={styles.floatingLabel}>
                            <input
                                type="text"
                                placeholder=" "
                                value={index === 0 ? newPassword : confirmPassword}
                                onChange={(e) => index === 0 ? setNewPassword(e.target.value) : setConfirmPassword(e.target.value)}
                                className={`form-control ${styles.input}`}
                            />
                            <label>{label}</label>
                        </div>
                    ))}
                </div>
                <div className="mt-5">
                    <ButtonLogin content="Salvar" onClick={handleSubmit} />
                </div>
            </div>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </div>
    );
}