import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { faHome, faBars, faBell, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../NavBar.module.scss';
import { Dropdown, Modal } from 'react-bootstrap';
import sideBarLogo from '../../../assets/images/sidebar-logo.png';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import ButtonGray from '../../Buttons/ButtonGray';
import { BASE_URL } from '../../../config';

interface Notification {
    id: number;
    message: string;
    isRead: boolean;
}

interface NavBarProps {
    onToggleMenu?: (isMenuOpen: boolean) => void;
}

const NavBarRegister: React.FC<NavBarProps> = ({ onToggleMenu }) => {
    const [menuOpen, setMenuOpen] = useState(localStorage.getItem('isMenuOpen') === 'true');
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [userName, setUserName] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('isMenuOpen', menuOpen.toString());
        if (onToggleMenu) {
            onToggleMenu(menuOpen);
        }
    }, [menuOpen, onToggleMenu]);

    useEffect(() => {
        // Simula a busca de notificações
        const fetchNotifications = async () => {
            const fetchedNotifications: Notification[] = [
                // { id: 1, message: 'Nova mensagem de suporte', isRead: false },
                // { id: 2, message: 'Atualização disponível', isRead: false },
                // { id: 3, message: 'Seu perfil foi atualizado', isRead: true }
            ];
            setNotifications(fetchedNotifications);
            setUnreadCount(fetchedNotifications.filter((n) => !n.isRead).length);
        };

        fetchNotifications();
    }, []);

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token');
    
            if (!token) {
                console.error('Nenhum token encontrado!');
                return;
            }
    
            try {
                const res = await fetch('https://webservices.gfttech.com.br/api/v1/usuario/perfil-usuario', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!res.ok) {
                    throw new Error('Erro ao buscar perfil do usuário');
                }
    
                const data = await res.json();
                const fullName = data.Usuario.userName;
    
                // Extrair apenas o nome e sobrenome
                const nameParts = fullName.split(' ');
                const firstName = nameParts[0];
                const lastName = nameParts[nameParts.length - 1];
                const shortName = `${firstName} ${lastName}`;
    
                setUserName(shortName); // Armazena apenas o nome e sobrenome
            } catch (error) {
                console.error('Erro ao buscar perfil do usuário:', error);
            }
        };
    
        fetchUserProfile();
    }, []);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleAccount = () => navigate('/Agente/MinhaConta');
    const handleLogout = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const Logout = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error('Nenhum token encontrado!');
            return;
        }

        try {
            const res = await fetch(`${BASE_URL}/sair`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                throw new Error('Logout falhou!');
            }

            localStorage.removeItem('token');
            localStorage.clear();
            window.location.href = '/login';
        } catch (error) {
            console.error('Erro no logout:', error);
        }
    };

    const markNotificationAsRead = (id: number) => {
        const updatedNotifications = notifications.map((notification) => {
            if (notification.id === id) {
                return { ...notification, isRead: true };
            }
            return notification;
        });
        setNotifications(updatedNotifications);
        setUnreadCount(updatedNotifications.filter((n) => !n.isRead).length);
    };

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.sideBar} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.header}>
                    <img src={sideBarLogo} alt="Logo" />
                    <FontAwesomeIcon
                        icon={faBars}
                        onClick={toggleMenu}
                        className={styles.bars}
                    />
                </div>
                <div className={styles.content}>
                    <NavLink to="/HomeCadastro" className={styles.navItem}>
                        <FontAwesomeIcon icon={faHome} className={styles.navIcon} />
                        <span className={styles.textItem}>Home</span>
                    </NavLink>

                    <div className={styles.userNameContainer}>
                        <span className={styles.userName}>
                            <FontAwesomeIcon icon={faUserTie} className='me-2'/> {userName}
                        </span>
                    </div>

                </div>
            </div>

            {!menuOpen && (
                <div className={styles.toggleMenu}>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className={styles.barsIconSemiTransparent} />
                </div>
            )}

            <div className={`${styles.topBar} ${menuOpen ? styles.menuOpen : ''}`}>
                <div className={styles.topBarIconsContainer}>
                    {/* <AvailableBalance/> */}
                    <Dropdown>
                        <Dropdown.Toggle as="div" className={styles.dropdownIcon}>
                            <FontAwesomeIcon icon={faBell} className={styles.topBarIcons} />
                            {unreadCount > 0 && <span className={styles.notificationBadge}>{unreadCount}</span>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={styles.dropdown}>
                            {notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <Dropdown.Item
                                        key={notification.id}
                                        className={styles.dropdownItem}
                                        onClick={() => markNotificationAsRead(notification.id)}
                                    >
                                        {notification.message}
                                        {!notification.isRead && <span className={styles.unreadIndicator} />}
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item className={styles.dropdownItem}>
                                    Nenhuma notificação
                                </Dropdown.Item>
                            )}
                            {/*<div className="d-flex justify-content-center mt-2 fs-6">
                                <a href="/Notificacoes" className={styles.link}> Ver todas </a>
                            </div>*/}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle as="div" className={styles.dropdownIcon}>
                            <FontAwesomeIcon icon={faUserTie} className={styles.topBarIcons} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={styles.dropdown}>
                            <Dropdown.Item 
                                onClick={handleAccount}
                                className={styles.dropdownItem}
                            >
                                Minha Conta
                            </Dropdown.Item>
                            <Dropdown.Item 
                                onClick={handleLogout}
                                className={styles.dropdownItem}
                            >
                                Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title className={styles.logoutTitle}>Confirmar Saída?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <ButtonGray content="Cancelar" onClick={handleCloseModal} />
                            <ButtonPrimary content="Sair" onClick={Logout} />
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default NavBarRegister;