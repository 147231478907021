import { useState } from 'react';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import styles from '../ResetPassword.module.scss';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import ToastWarning from '../../ToastWarning';

const CreatePassword = ({text}: {text: string}) => {
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirm, setConfirm] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const buttonDisable = password.trim() === "" || confirm.trim() === "" || email.trim() === "";
    
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !buttonDisable) handleSubmit();
    };

    const handleSubmit = async () => {
        if(password !== confirm){
            setError("As senhas não coincidem.");
            return null;
        }
         if(!text){
            setError("E-mail não encontrado!");
            return null;
         }

        try{
            const res = await axios.post(`${BASE_URL}/senha/resetar`,{
                otp: text,
                email: email,
                new_user_password: password,
                new_user_password_confirmation: confirm
            });
            setSuccess("Senha alterada com sucesso!");
            setTimeout(() => {
                window.location.href = "/";
            }, 4000);
            return res;

        } catch(err){
            setError("Erro ao tentar resetar senha!");
            console.log("Error:", err);
        }
    };

    return(
        <>
             <div className={`${styles.inputGroup} mt-5`}>
                <div className={`${styles.floatingLabel}`}>
                    <input
                        type="email"
                        id="login"
                        placeholder=" "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`form-control ${styles.input}`}
                    />
                    <label htmlFor="login">E-mail</label>
                </div>
                <div className={`${styles.floatingLabel}`}>
                    <input
                        type="password"
                        id="login"
                        placeholder=" "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`form-control ${styles.input}`}
                    />
                    <label htmlFor="login">Nova senha</label>
                </div>
                <div className={`${styles.floatingLabel}`}>
                    <input
                        type="password"
                        id="password"
                        placeholder=" "
                        value={confirm}
                        onChange={(e) => setConfirm(e.target.value)}
                        onKeyDown={handleKeyPress}
                        className={`form-control ${styles.input}`}
                    />
                    <label htmlFor="password">Confirmar senha</label>
                </div>
                {error && <p className="text-danger text-center mt-4">{error}</p>}
                <div className="d-flex justify-content-center mt-5">
                    <ButtonPrimary content="Prosseguir" disabled={buttonDisable} onClick={handleSubmit}/>
                </div>
          </div>
          {success && <ToastWarning message={success}/>}
        </>
    )
}

export default CreatePassword; 