import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ReturnBatchQuery.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../../components/PageSubTitle';
import PageTitle from '../../../../../components/PageTitle';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ReturnList from './ReturnList/index';
import ProcessingQueue from '../../../../../components/BatchQuery/ProcessingQueue';
import DropDownButtonPrimary from '../../../../../components/Buttons/DropdownButtonPrimary';
import FilterSearchReturn from '../../../../../components/BatchQuery/FIlterSearchReturn';
import { useLocation } from 'react-router-dom';
import Logo from '../../../../../components/Logo';
import ToastWarning from '../../../../../components/ToastWarning';
import { SimulationDataExcel } from './ReturnBatch';
import { CREATE_EXCEL_URL, URL_SIMULATIONS } from '../../../../../config';
import NavBarAgent from '../../../../../components/NavBar/Agent_Partner';

interface SimulationExcelData{
  cpf: string;
  convenant: string;
  bank: string;
  product: string;
  total: string;
  val_parcela: string;
  qntd_parcelas: number;
  rate: string;
}

export default function ReturnBatchQueryCovenantAgent() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [simulations, setSimulations] = useState<SimulationDataExcel[]>([]);
  const [excelData, setExcelData] = useState<SimulationExcelData[]>([]);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [processedCPFs, setProcessedCPFs] = useState<number>(0);
  const [cpfFilter, setCpfFilter] = useState<string>('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lote = searchParams.get("lote");
  const totalCpfs = JSON.parse(searchParams.get("tC") || '0');
  const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
  const covenantDescriptionLocalStorage = formDataLocalStorage.covenantDescription;

  const dropdownItems = [
    { label: covenantDescriptionLocalStorage, link: '/Agente/ConsultaLote/Convenios/EnvioArquivo' },
    { label: 'Outro Convênio', link: '/Agente/ConsultaLote/Convenios' }
  ];
  const subtitleTextClient = "Confira o(s) retorno(s) da sua simulação em lote.";

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`${URL_SIMULATIONS}/proposal/simulation/find-by-batch?batchId=${lote}`);
      
      const data: SimulationDataExcel[] = await response.json();
        if (data.length > 0) {
          setSimulations(data);
          const uniqueCpfs = new Set(data.map((simulation) => simulation.cpf));
          const processedCpfsCount = uniqueCpfs.size;
          setProcessedCPFs(processedCpfsCount);
    
          const formattedData: SimulationExcelData[] = data.flatMap((simulation) => {
            if (!simulation.conditions) return [];
            const conditions = simulation.conditions.conditions;
    
            const conditionData = Object.entries(conditions || {}).flatMap(([key, bankConditions]) => {
              if (Array.isArray(bankConditions)) {
                return bankConditions
                  .filter((condition) => condition.status === 'success') 
                  .map((bankCondition) => {
                    const bankData = bankCondition || {};  
                    return {
                      cpf: simulation.cpf,
                      convenant: simulation.covenantDescription,
                      bank: key.toUpperCase(),
                      product: bankData.product || 'Produto não informado',
                      total: bankData.grossAmount || '0.00',
                      val_parcela: bankData.installmentAmount || '0.00',
                      qntd_parcelas: parseInt(bankData.installmentQuantity || '0', 10),
                      rate: bankData.clientRate || '0.00',
                    };
                  });
              }
              return [];
            });
            return [...conditionData]; 
          });
          setExcelData(formattedData);
        }
    } catch (err) {
      console.log(err);
    }
  };
   
  useEffect(() => {
    fetchSimulations();
  }, [])

  const handleExportButton = async () => {
    try{
      const requestBody = {
        fileName: "Consulta_Lote_Convenios",
        pageName: "Listagem Simulações",
        pageHeader: [
          "CPF",
          "Convênio",
          "Banco",
          "Produto",
          "Valor Total",
          "Valor Parcelas",
          "Parcelas",
          "Taxa"
        ],
        data: excelData
      }

      const res = await fetch(`${CREATE_EXCEL_URL}`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (res.ok) {
        const blob = await res.blob(); 
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Retorno_Simulacao_Convenio.xlsx"); 
        document.body.appendChild(link);
        link.click();

        if (link.parentNode) {
          link.parentNode.removeChild(link);
          setToastMessage('Arquivo criado com sucesso!')
        } else {
          console.warn("link não foi adicionado corretamente");
        }
      } else {
        setToastMessage("Erro ao criar arquivo");
      }
    } catch(error){
      console.log(error);
    }
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Consulta em Lote</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-3`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start'>
              <ProcessingQueue processedCPFs={processedCPFs} totalCPFs={totalCpfs} />
            </Col>
            <Col className='d-flex justify-content-center'>
              <FilterSearchReturn cpfFilter={cpfFilter} setCpfFilter={setCpfFilter} />
            </Col>
            <Col className='d-flex justify-content-end'>
              {/* <ButtonPrimary content="Exportar" onClick={handleExportButton} /> */}
            </Col>
          </Row>
          <div className='mt-5'>
            <ReturnList cpfFilter={cpfFilter} setProcessedCPFs={setProcessedCPFs} totalCpfs={totalCpfs} />
          </div>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-end mt-1'>
              <DropDownButtonPrimary title="Nova Simulação" items={dropdownItems} />
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastWarning message={toastMessage} /> }
      </motion.div>
    </>
  );
}
