import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styles from './ReturnList.module.scss';
import { useLocation } from "react-router-dom";
import SpinnerDefault from "../../../../../../components/Spinner";
import { ReturnListProps, SimulationData } from "./ReturnListInterface";
import { URL_SIMULATIONS } from "../../../../../../config";

export default function ReturnList({ cpfFilter, setProcessedCPFs, totalCpfs }: ReturnListProps) {
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [processedCpfs, setProcessedCpfsLocal] = useState<number>(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lote = searchParams.get("lote");

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`${URL_SIMULATIONS}/proposal/simulation/find-by-batch?batchId=${lote}`);

      if (!response.ok) {
        throw new Error(`Erro ao carregar os dados. Status: ${response.status}`);
      }

      const data: SimulationData[] = await response.json();
      if (data.length > 0) {
        setSimulations(data);
        const uniqueCpfs = new Set(data.map((simulation) => simulation.cpf));
        const processedCpfsCount = uniqueCpfs.size;
        setProcessedCPFs(processedCpfsCount);
        setProcessedCpfsLocal(processedCpfsCount);
      }
    } catch (err) {
      console.log(err);
      if (retryCount < 3) {
        setRetryCount(retryCount + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimulations();
  }, [lote, retryCount, setProcessedCPFs]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (processedCpfs < totalCpfs) {
        fetchSimulations();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [processedCpfs, totalCpfs]);

  const formatCurrency = (value: string | number | null) => {
    if (value === null) return "-";
    const number = typeof value === "string" ? parseFloat(value) : value;
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const renderCreditData = (simulation: SimulationData) => {
    let creditRows: JSX.Element[] = [];

    // Check if conditions are defined and if they contain valid data
    const conditions = simulation.conditions?.conditions;
    if (!conditions) return creditRows; // Return empty if conditions is undefined

    for (const [key, bankConditions] of Object.entries(conditions)) {
      const bankName = key.toUpperCase();

      bankConditions.forEach((condition, index) => {
        if (condition.status === "success") {
          creditRows.push(
            <tr key={`${simulation.id}-${key}-${index}`}>
              <td>{simulation.cpf}</td>
              <td>{simulation.covenantDescription}</td>
              <td>{bankName}</td>
              <td>{condition.product}</td>
              <td>{formatCurrency(condition.netAmount ?? null)}</td>
              <td>{formatCurrency(condition.installmentAmount ?? null)}</td>
              <td>{condition.installmentQuantity || "-"}</td>
              <td>{condition.clientRate ? `${condition.clientRate}%` : "-"}</td>
            </tr>
          );
        } else if (condition.status === "failed" && condition.error) {
          creditRows.push(
            <tr key={`${simulation.id}-${key}-error`}>
              <td>{simulation.cpf}</td>
              <td>{simulation.covenantDescription}</td>
              <td>{bankName}</td>
              <td colSpan={5}><span>{condition.error}</span></td>
            </tr>
          );
        }
      });
    }

    return creditRows;
  };

  const filteredSimulations = simulations.filter(simulation => {
    return cpfFilter === "" || simulation.cpf.includes(cpfFilter);
  });

  return (
    <div className={styles.tableContainer}>
      <div className={styles.scrollableTable}>
        <Table className={`${styles.tableStyle} table table-responsive`}>
          <thead>
            <tr>
              <th>CPF</th>
              <th>CONVÊNIO</th>
              <th>BANCO</th>
              <th>PRODUTO</th>
              <th>VLR. TOTAL</th>
              <th>VLR. PARCELAS</th>
              <th>PARCELAS</th>
              <th>TAXA</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8}>
                  <SpinnerDefault/>
                </td>
              </tr>
            ) : filteredSimulations.length > 0 ? (
              filteredSimulations.flatMap((simulation) => renderCreditData(simulation))
            ) : (
              <tr>
                <td colSpan={8}>
                    <SpinnerDefault/>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
