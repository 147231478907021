import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface FilterUnitaryProps{
    onFilter: (
        cpf: string,
        startDate: string,
        endDate: string
    ) => void;
}

const FilterUnitary = ({ onFilter }: FilterUnitaryProps) => {
    const [cpf, setCpf] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const handleSearch = () => {
        onFilter(cpf, startDate, endDate); 
      };

    return(
        <Form>
            <Row className="align-items-center">
                <Col xs={12} md={4}>
                    <Form.Group controlId="cpfField">
                        <Form.Control
                            type="text"
                            placeholder="Pesquisar por CPF..."
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col xs={6} md={3}>
                    <Form.Group controlId="formStartDate">
                        <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col xs={6} md={3}>
                    <Form.Group controlId="formEndDate">
                        <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12} md={2} className="d-flex align-items-end">
                    <ButtonPrimary
                        content={<FontAwesomeIcon icon={faSearch} />}
                        onClick={handleSearch}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default FilterUnitary;