import { useState } from "react";
import styles from '../ResetPassword.module.scss';
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import axios from "axios";
import { BASE_URL } from "../../../config";
import ToastWarning from "../../ToastWarning";

interface SendEmailProps{
    onProceed: () => void;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const SendEmail = ({onProceed, setValue}: SendEmailProps) => {
    const [email, setEmail] = useState<string>("");
    const [confirm, setConfirm] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !buttonDisable) handleSubmit();
    };

    const buttonDisable = email.trim() === "" || confirm.trim() === "";
    
    const handleSubmit = async () => {
        if(email !== confirm){
            setError("Os e-mails não coincidem.");
            return null;
        }

        try{
            const res = await axios.post(`${BASE_URL}/senha/esqueci`,{
                email: email,
                email_confirmation: confirm,
                system: "acelere"
            });
            setSuccess("Código de confirmação enviado por e-mail");
            setTimeout(() => {
                onProceed();
            }, 4000);
            return res;

        } catch(err){
            setError("Erro ao enviar código de confirmação");
            console.log("Error", err);
        }
    };

    return(
        <>
            <div className={`${styles.inputGroup} mt-5`}>
                <div className={`${styles.floatingLabel}`}>
                    <input
                        type="email"
                        id="login"
                        placeholder=" "
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            setValue(e.target.value)
                        }}
                        className={`form-control ${styles.input}`}
                    />
                    <label htmlFor="login">E-mail</label>
                    </div>
                    <div className={`${styles.floatingLabel}`}>
                    <input
                        type="email"
                        id="password"
                        placeholder=" "
                        value={confirm}
                        onChange={(e) => setConfirm(e.target.value)}
                        onKeyDown={handleKeyPress}
                        className={`form-control ${styles.input}`}
                    />
                    <label htmlFor="password">Confirmar E-mail</label>
                </div>
                {error && <p className="text-danger text-center mt-4">{error}</p>}
                <div className="d-flex justify-content-center mt-5">
                    <ButtonPrimary content="Prosseguir" disabled={buttonDisable} onClick={handleSubmit}/>
                </div>
          </div>
          {success && <ToastWarning message={success}/>}
        </>
    )
}

export default SendEmail;