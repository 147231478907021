import React, { useState, useEffect } from "react";
import { Modal, Alert, InputGroup, FormControl, Button } from "react-bootstrap";
import axios from "axios";

type LinkProposalProps = {
  show: boolean;
  onHide: () => void;
  record: any;
};

export default function LinkProposal({ show, onHide, record }: LinkProposalProps) {
  const [linkData, setLinkData] = useState<{ url: string } | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (show && record?.proposal_number) {
      const fetchLink = async () => {
        try {
          const token = localStorage.getItem("token");
          let response;

          if (record.bank.name === "C6 BANK") {
            response = await axios.get(
              `https://webapi.gfttech.com.br/api/c6/proposal/web-formalization?proposalNumber=${record.proposal_number}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setLinkData({ url: response.data.url });
            setErrorMessage(null);
          } else if (record.bank.name === "BANCO FACTA") {
            response = await axios.get(
              `https://acelereai.gfttech.com.br/api/v2/banking-services/facta/proposal/formalization-url?proposalNumber=${record.proposal_number}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.data.url_formalizacao) {
              setLinkData({ url: response.data.url_formalizacao });
              setErrorMessage(null);
            } else {
              setErrorMessage("Nenhuma URL de formalização encontrada para o número de proposta informado!");
              setLinkData(null);
            }
          }
        } catch (error: any) {
          setErrorMessage("Nenhuma URL de formalização encontrada para o número de proposta informado!");
          setLinkData(null);
        }
      };

      fetchLink();
    } else {
      setLinkData(null);
      setErrorMessage(null);
    }
  }, [show, record]);

  const handleCopy = () => {
    if (linkData?.url) {
      navigator.clipboard.writeText(linkData.url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Link da Proposta {record?.proposal_number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage ? (
          <Alert variant="secondary">{errorMessage}</Alert>
        ) : linkData ? (
          <>
            <InputGroup className="mb-3">
              <FormControl
                value={linkData.url}
                readOnly
                aria-label="Link da formalização"
              />
              <Button variant="outline-primary" onClick={handleCopy}>
                Copiar
              </Button>
            </InputGroup>
            {copied && <Alert variant="success">Link copiado para a área de transferência!</Alert>}
          </>
        ) : (
          <Alert variant="info">Carregando informações...</Alert>
        )}
      </Modal.Body>
    </Modal>
  );
}