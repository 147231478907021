import { useRef, useState } from "react";
import styles from '../ResetPassword.module.scss';
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import axios from "axios";
import { BASE_URL } from "../../../config";
import ToastWarning from "../../ToastWarning";

interface ConfirmCodeProps{
    onProceed: () => void;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    text: string;
}

const ConfirmCode = ({onProceed, setValue, text}: ConfirmCodeProps) => {
    const [code, setLocalCode] = useState<string[]>(Array(6).fill(""));
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const fullCode = code.join("");
    const buttonDisable = fullCode.length !== 6;

    const handleChange = (index: number, value: string) => {
        if (/^\d?$/.test(value)) {
          const newCode = [...code];
          newCode[index] = value;
          setLocalCode(newCode);
          setValue(newCode.join(""));
    
          if (value && index < 5) {
            inputRefs.current[index + 1]?.focus();
          }
        }
      };

    const handleBackspace = (index: number) => {
        if (index > 0 && !code[index]) {
          inputRefs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = async () => {
        if(!text){
            setError("E-mail não encontrado!");
            return null;
        }

        try{
            const res = await axios.post(`${BASE_URL}/senha/verificar`,{
                otp: fullCode,
                email: text
            });
            setSuccess("Código validado com sucesso!");
            setTimeout(() => {
                onProceed();
            }, 3000)
            return res;

        } catch(err) {
            setError("Código inválido!");
            console.log("Error", err);
        }
    }

    return(
        <>
            <div className="d-flex flex-column align-items-center mt-4">
                <h6 className="mb-5">Digite o código de cofirmação enviado por e-mail:</h6>
                <div className="d-flex gap-3">
                    {code.map((digit, index) => (
                        <div key={index} className={`${styles.inputGroup}`}>
                            <input
                                ref={(ref) => {inputRefs.current[index] = ref}}
                                type="text"
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Backspace") handleBackspace(index);
                                }}
                                maxLength={1}
                                className={`${styles.codeInputs} text-center`}
                            />
                        </div>
                    ))}
                </div>
                {error && <p className="text-danger text-center mt-4">{error}</p>}
                <div className="mt-5">
                    <ButtonPrimary content="Prosseguir" disabled={buttonDisable} onClick={handleSubmit}/>
                </div>
            </div>
            {success && <ToastWarning message={success}/>}
        </>
    )
}

export default ConfirmCode;