import { useState } from "react";
import styles from './ResetPassword.module.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import aceleraLogoMain from "../../assets/images/acelera_logo.png";
import SendEmail from "./SendEmail";
import ConfirmCode from "./ConfirmCode";
import CreatePassword from "./CreatePasswod";

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const nextStep = () => {
    if (step < 3) setStep(step + 1);
  };

  return (
    <>
       <div className={`${styles.loginContainer} p-5`}>
          <img src={aceleraLogoMain} className="mb-5" alt="Logo" />
          
            <div className={`${styles.stepper} progress mb-4`}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{width: `${(step / 3) * 100}%`}}
                ></div>
            </div>

            <div className={`${styles.steperContent} d-flex justify-content-center mt-3`}>
                {step === 1 && <SendEmail onProceed={nextStep} setValue={setEmail}/>}
                {step === 2 && <ConfirmCode text={email} onProceed={nextStep} setValue={setCode}/>}
                {step === 3 && <CreatePassword text={code}/>}
            </div>
        </div>
    </>
  );
};

export default ResetPassword;